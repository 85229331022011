import { useNavigate } from 'react-router-dom';
import { useStore, convert } from 'idpBase/store';
import Button from 'common/components/Button';
import notFoundImg from 'common/static/images/notFound.png';
import JSRuntime from 'common/utils/JSRuntime';
import Utils from 'common/utils';
import refusedImg from '../assets/refused.png';
import joinedImg from '../assets/joined.png';
import { BTN_TEXT, RESULT_TYPES } from '../constants/Constants';
import ResultScss from '../style/Result.module.scss';

const ResultPage = () => {
  const { invitationStore } = useStore();
  const navigate = useNavigate();

  const { resultType, invitationInfo, expiredErrorText, onlyApp } = invitationStore;
  const companyName = m.get(invitationInfo, 'account.companyInfo.companyName');

  const goLogin = () => {
    if (onlyApp === JSRuntime.front2BackApp.meeting) {
      Utils.safeRedirect('https://meeting.tencent.com');
    } else if (onlyApp === JSRuntime.front2BackApp.docs) {
      const url = new URL(window.location.href);
      url.searchParams.set('app', JSRuntime.front2BackApp.docs);
      window.history.replaceState({}, '', url);
      navigate('/login', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  };

  // TODO 关闭当前页需要一个更好的方案
  const closeCurrentPage = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
      window.location.href = 'about:blank';
      window.close();
    } else {
      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  };

  const btnText = BTN_TEXT[onlyApp] ? BTN_TEXT[onlyApp] : '前往登录';

  const renderContent = () => {
    switch (resultType) {
      case RESULT_TYPES.EXPIRED:
        return (
          <>
            <img className={ResultScss.notFoundImg} src={notFoundImg} alt="" />
            <h1 className={ResultScss.expireTitle}>当前链接已失效</h1>
            <div className={ResultScss.notFound}>{expiredErrorText || '页面未找到'}</div>
          </>
        );
      case RESULT_TYPES.HAS_JOINED:
        return (
          <>
            <img className={ResultScss.resultImg} src={joinedImg} alt="" />
            <h1 className={ResultScss.status}>您已经在该企业中，无需重复加入</h1>
            <div className={ResultScss.companyName}>{companyName}</div>
            <Button className={ResultScss.btn} color="secondary" onClick={goLogin}>
              {btnText}
            </Button>
          </>
        );
      case RESULT_TYPES.JOIN_SUCCESS:
        return (
          <>
            <img className={ResultScss.resultImg} src={joinedImg} alt="" />
            <h1 className={ResultScss.status}>加入成功</h1>
            <div className={ResultScss.companyName}>{companyName}</div>
            <Button className={ResultScss.btn} color="secondary" onClick={goLogin}>
              {btnText}
            </Button>
          </>
        );
      case RESULT_TYPES.REFUSED:
        return (
          <>
            <img className={ResultScss.resultImg} src={refusedImg} alt="" />
            <h1 className={ResultScss.status}>已拒绝</h1>
            <div className={ResultScss.companyName}>{companyName}</div>
            <Button className={ResultScss.btn} color="secondary" onClick={closeCurrentPage}>
              关闭页面
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return <div className={ResultScss.resultWrapper}>{renderContent()}</div>;
};

export default convert(ResultPage);
