import BaseApi from 'common/apiRequest/apis/BaseApi';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/adminV2';

class UsersApi extends BaseApi {
  public requestInstance = new Request(APINames.USERS);

  // 修改个人版名称
  updatePersonalName(params: { name: string }) {
    return this.requestInstance.patch(['selfUserName'], params);
  }
}

export default new UsersApi();
