/**
 * 阅读同意软件服务协议
 * */
import { TabFocusWrapper } from 'common/components/TabFocusDom';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { Checkbox, CheckboxProps } from 'tdesign';
import AgreementScss from './Agreement.module.scss';

interface IProps extends CheckboxProps {
  shaking?: boolean;
}

const Agreement = (props: IProps) => {
  const { shaking, ...extraProps } = props;
  const [shakingClass, setShakingClass] = React.useState('');

  // 借助生命周期，外部可以在每次更改 shaking 时先setShaking(false); 再setShaking(true); 来实现预期的抖动效果
  React.useEffect(() => {
    if (shaking) {
      setShakingClass('animate__animated animate__headShake');
    } else {
      setShakingClass('');
    }
  }, [shaking]);

  return (
    <TabFocusWrapper>
      <Checkbox className={Utils.uniteClass(AgreementScss.agreement, shakingClass)} {...extraProps}>
        我已阅读并同意
        <a href={JSRuntime.agreementUrl.service} target="_blank">
          《服务协议》
        </a>
        和
        <a href={JSRuntime.agreementUrl.privacy} target="_blank">
          《隐私政策》
        </a>
      </Checkbox>
    </TabFocusWrapper>
  );
};

export default React.memo(Agreement);
