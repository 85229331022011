// 黑名单 用来隐藏一些选项
const blackList = [
  ['教育', '研究员'],
  ['医疗健康', '康复治疗师'],
  ['金融/财经', '精算师'],
  ['金融/财经', '审计师'],
  ['金融/财经', '金融风险管理师'],
  ['媒体', '编辑'],
];
export const allList = [
  {
    name: '管理',
    children: [
      {
        name: 'CEO',
        materialDesc: ['在职证明/劳动合同', '公司营业执照/事业单位法人证明'],
        desc: [
          '1个月内开具的可证明认证单位和职位属实的在职证明或有效期内的劳动合同，需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” ，职称需为CEO/首席执行官/Chief Executive Officer；',
          '公司营业执照或事业单位法人证明。需为最新版的高清彩色原件，或加盖红色公章的黑白复印件。',
        ],
        id: '0',
      },
      {
        name: 'CTO',
        materialDesc: ['在职证明/劳动合同', '公司营业执照/事业单位法人证明'],
        desc: [
          '1个月内开具的可证明认证单位和职位属实的在职证明或有效期内的劳动合同，需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” ，职称需为CTO/首席技术官/Chief Technology Officer；',
          '公司营业执照或事业单位法人证明。需为最新版的高清彩色原件，或加盖红色公章的黑白复印件。',
        ],
        id: '1',
      },
      {
        name: '总监',
        materialDesc: ['在职证明/劳动合同', '公司营业执照/事业单位法人证明'],
        desc: [
          '1个月内开具的可证明认证单位和职位属实的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。职称需为总监/副总监；',
          '公司营业执照或事业单位法人证明。需为最新版的高清彩色原件，或加盖红色公章的黑白复印件。',
        ],
        id: '2',
      },
      {
        name: '总经理',
        materialDesc: ['在职证明/劳动合同', '公司营业执照/事业单位法人证明'],
        desc: [
          '1个月内开具的可证明认证单位和职位属实的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。职称需为总经理/副总经理；',
          '公司营业执照或事业单位法人证明。需为最新版的高清彩色原件，或加盖红色公章的黑白复印件。',
        ],
        id: '3',
      },
      {
        name: '法定代表人',
        materialDesc: ['在职证明/劳动合同', '公司营业执照/事业单位法人证明'],
        desc: [
          '1个月内开具的可证明认证单位和职位属实的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。职称需为法定代表人/法人；',
          '公司营业执照或事业单位法人证明。需为最新版的高清彩色原件，或加盖红色公章的黑白复印件。',
        ],
        id: '4',
      },
      {
        name: '人力资源专员',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间”。',
          '您的职位证明材料，职位需为人力资源相关岗位。需盖有单位公章。',
        ],
        id: '5',
      },
      {
        name: 'HRBP',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间”。',
          '您的职位证明材料，职位需为HRBP相关岗位。需盖有单位公章。',
        ],
        id: '6',
      },
    ],
  },
  {
    name: '教育',
    children: [
      {
        name: '教师',
        materialDesc: ['教师资格证/教师职称证明', '在职证明/劳动合同/聘书'],
        desc: [
          '由国家教育部颁发的教师资格证或教师职称证明。需清晰展示个人信息。',
          '1 个月内由公办、民办学校开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。职位需为教师/老师。',
        ],
        id: '7',
      },
      {
        name: '教授',
        materialDesc: ['职称证明', '在职证明/劳动合同/聘书'],

        desc: [
          '由人社局/人事厅或者高等院校颁发的教授/副教授职称证明。需清晰展示个人信息。',
          '1个月内由高等院校开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。职位需为副教授/教授。',
        ],
        id: '8',
      },
      {
        name: '研究员',
        desc: [
          '由研究机构或者高等院校颁发的职位证明。需清晰展示个人信息。',
          ' 3个月内开具的在职证明。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间”。',
        ],
        id: '9',
      },
    ],
  },
  {
    name: '医疗健康',
    children: [
      {
        name: '医生',
        materialDesc: ['医师资格证书', '医师执业证书', '在职证明/劳动合同', '职称证明'],
        desc: [
          '医师资格证书（红皮）：姓名页、个人信息页、执业机构信息页；',
          '医生执业证书（支持绿皮实体证书或是带可扫描二维码的电子版）：姓名页、个人信息页、执业机构信息页；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“医师职称、所在部门、所在科室、入职时间、身份证号、姓名”或就职医院官网截图，内容包含姓名、个人头像、所处科室、职称；',
          '职称证明：清晰展示个人信息、管理号/编号、个人专业类别的职称证书。',
        ],
        id: '10',
      },
      {
        name: '药师',
        materialDesc: ['执业药师资格证书', '在职证明/劳动合同'],
        desc: [
          '执业药师资格证书：清晰展示个人信息、管理号、个人专业类别',
          '1 个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '11',
      },
      {
        name: '营养师',
        materialDesc: ['注册营养师/注册营养技师/公共营养师资格证书', '在职证明/劳动合同'],
        desc: [
          '中国营养学会颁发的有效期内的注册营养师/注册营养技师资格。需展示个人信息、证书编号、执业工种和等级或者：二级及以上的人社部公共营养师资格(职业技能等级)证书',
          '1 个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '12',
      },
      {
        name: '康复治疗师',
        desc: [
          '人社部颁发的初级以上职称康复治疗师技师证书',
          '3 个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名。',
        ],
        id: '13',
      },
      {
        name: '心理咨询师',
        materialDesc: ['国家二级/三级心理咨询师证', '在职证明/劳动合同'],
        desc: [
          '国家二级/三级心理咨询师证：包括个人照片页与个人信息页，彩色原件，证件信息清晰；',
          '1 个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '14',
      },
    ],
  },
  {
    name: '法律',
    children: [
      {
        name: '律师',
        materialDesc: ['律师执业证', '在职证明/劳动合同'],
        desc: [
          '律师执业证，以下两页请务必同时上传：（1）个人信息页：包含执业机构、执业证号、持证人信息等；（2）律师年度考核备案页。',
          '1 个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '15',
      },
    ],
  },
  {
    name: '金融/财经',
    children: [
      {
        name: '金融分析师',
        materialDesc: ['个人资质截图', '证券从业资格证 （SAC）', '在职证明/劳动合同'],
        desc: [
          '中国证券业协会官网信息公示中个人资质截图，且职业岗位为证券投资咨询(分析师)；',
          '证券从业资格证 （SAC）；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '16',
      },
      {
        name: '证券投资经理',
        materialDesc: ['个人资质截图', '证券从业资格证 （SAC）', '在职证明/劳动合同'],
        desc: [
          '中国证券业协会官网信息公示中个人资质截图，且职业岗位为(投资顾问)；',
          '证券从业资格证 （SAC）；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
      },
      {
        name: '证券经纪人',
        materialDesc: ['个人资质截图', '证券从业资格证 （SAC）', '在职证明/劳动合同'],
        desc: [
          '中国证券业协会官网信息公示中个人资质截图，且职业岗位为证券经纪人；',
          '证券从业资格证 （SAC）；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '17',
      },
      {
        name: '基金经理',
        materialDesc: ['个人资质截图', '基金从业资格证书', '在职证明/劳动合同'],
        desc: [
          '中国证券投资基金业协会官网信息公示中个人资质截图；',
          '基金从业资格证书；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '18',
      },
      {
        name: '基金投资经理',
        materialDesc: ['个人资质截图', '基金从业资格证书', '在职证明/劳动合同'],
        desc: [
          '中国证券投资基金业协会官网信息公示中个人资质截图；',
          '基金从业资格证书；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '19',
      },
      {
        name: '财经作家',
        materialDesc: [
          '出版合同/数字版权合同/作品登记证书/在职证明/劳动合同',
          '图书实体书籍封面页+扉页',
        ],
        desc: [
          '包含姓名、身份证号、公章、在有效期内的下述几种证书之一：出版合同/数字版权合同/作品登记证书/在职证明/劳动合同；',
          '通过主流出版社公开出版三本以及以上财经类书籍的的图书实体书籍封面页+扉页；',
          '在补充说明里请补充图书购买链接、国际标准ISBN书号。',
        ],
        id: '20',
      },
      {
        name: '会计师',
        materialDesc: ['注册会计师执业证书(CPA)', '在职证明/劳动合同'],
        desc: [
          '有效期内的注册会计师执业证书(CPA)，不支持考试合格证、非执业会员证书；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '21',
      },
      {
        name: '审计师',
        desc: ['有效期内的审计专业技术中级资格证书'],
        id: '22',
      },
      {
        name: '精算师',
        desc: ['准精算师职业资格证或者正精算师职业资格证或精算师职业资格证'],
        id: '23',
      },
      {
        name: '基金从业人员',
        materialDesc: ['个人资质截图', '在职证明/劳动合同'],
        desc: [
          '中国证券投资基金业协会官网信息公示中个人资质截图；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '24',
      },
      {
        name: '期货从业人员',
        materialDesc: ['个人资质截图', '在职证明/劳动合同'],
        desc: [
          '中国期货业协会官网信息公示中个人资质截图；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '25',
      },
      {
        name: '证券从业人员',
        materialDesc: ['个人资质截图', '证券从业资格证 （SAC）', '在职证明/劳动合同'],
        desc: [
          '中国证券业协会官网信息公示中个人资质截图，且岗位为一般证券业务/证券经纪业务/投资主办人/保荐代表人；',
          '证券从业资格证 （SAC）；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '26',
      },
      {
        name: '保险从业者',
        materialDesc: ['个人资质截图', '在职证明/劳动合同'],
        desc: [
          '有效期内的保险销售从业人员执业证/保险中介从业人员执业证书；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '27',
      },
      {
        name: '金融风险管理师',
        desc: [
          '金融风险管理师职业资格证书。需清晰展示个人信息。',
          '3个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名。',
        ],
        id: '28',
      },
    ],
  },
  {
    name: '媒体',
    children: [
      {
        name: '主持人',
        materialDesc: ['主持人资格证/播音员主持人证', '在职证明/劳动合同'],
        desc: [
          '主持人资格证/播音员主持人证。需清晰展示个人信息；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '29',
      },
      {
        name: '记者',
        materialDesc: ['新闻记者证', '在职证明/劳动合同'],
        desc: [
          '新闻记者证。需清晰展示个人信息。不接受记者资格考试合格证；',
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '30',
      },
      {
        name: '编辑',
        desc: [
          '出版专业职业资格证书。需清晰展示个人信息。',
          '责任编辑证书。需清晰展示个人信息。',
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。 ',
        ],
        id: '31',
      },
    ],
  },
  {
    name: '咨询',
    children: [
      {
        name: '咨询顾问',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名”。',
        ],
        id: '32',
      },
    ],
  },
  {
    name: '房地产',
    children: [
      {
        name: '房地产经纪人',
        materialDesc: ['国家房地产经纪专业人员职业资格证', '登记备案截图', '在职证明/劳动合同'],

        desc: [
          '国家房地产经纪专业人员职业资格证；',
          '当地住建委/住建局/房屋管理局或全国房地产经纪行业平台完成登记备案的房地产经纪人登记备案截图。清晰展示个信息、职业机构、个人专业级别需要为房地产经纪人；',
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间”。',
        ],
        id: '33',
      },
    ],
  },
  {
    name: 'IT/互联网',
    children: [
      {
        name: '产品',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。',
          '您的职位证明材料，职位需为产品相关岗位。需盖有单位公章。',
        ],
        id: '34',
      },
      {
        name: '设计师',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。',
          '您的职位证明材料，职位需为设计师相关岗位。需盖有单位公章。',
        ],
        id: '35',
      },
      {
        name: '软件工程师',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。',
          '您的职位证明材料，职位需为软件工程师相关岗位。需盖有单位公章。',
        ],
        id: '36',
      },
      {
        name: '数据工程师',
        materialDesc: ['在职证明/劳动合同'],
        desc: [
          '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。',
          '您的职位证明材料，职位需为数据工程师相关岗位。需盖有单位公章。',
        ],
        id: '37',
      },
    ],
  },
].map((item) => ({
  ...item,
  children: (item.children as Record<string, any>[]).filter(
    (child: Record<string, any>) =>
      !blackList.some((path) => item.name === path[0] && child.name === path[1]),
  ),
}));
export type TDaren = {
  name: string;
  materialDesc?: string[];
  desc?: (string | string[])[];
  otherDesc?: (string | string[])[];
  id?: string;
  children?: TDaren[];
};

export const baseDarenList: TDaren[] = [
  {
    name: '达人',
    children: [
      {
        name: '娱乐达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
        id: '50',
        desc: [
          '您需要填写您粉丝最多的社交平台的个人资料页链接；',
          '账号归属权证明：',
          [
            '站外主人视角的个人主页截图（主人登录视角且带有粉丝数）；',
            '认证人手持站外后台合影；',
            '站外账号实名认证截图；',
            '站外平台发布的最近一条视频的编辑界面截图。',
          ],
        ],
        otherDesc: [
          '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等。',
        ],
      },
      {
        name: '知识达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
        id: '51',
        desc: [
          '您需要填写您粉丝最多的社交平台的个人资料页链接；',
          '账号归属权证明：',
          [
            '站外主人视角的个人主页截图（主人登录视角且带有粉丝数）；',
            '认证人手持站外后台合影；',
            '站外账号实名认证截图；',
            '站外平台发布的最近一条视频的编辑界面截图。',
          ],
        ],
        otherDesc: [
          '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等。',
        ],
      },
      {
        name: '艺术达人',
        id: '52',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
        desc: [
          '您需要填写您粉丝最多的社交平台的个人资料页链接；',
          '账号归属权证明：',
          [
            '站外主人视角的个人主页截图（主人登录视角且带有粉丝数）；',
            '认证人手持站外后台合影；',
            '站外账号实名认证截图；',
            '站外平台发布的最近一条视频的编辑界面截图。',
          ],
        ],
        otherDesc: [
          '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等。',
        ],
      },
      {
        name: '游戏达人',
        id: '53',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
        desc: [
          '您需要填写您粉丝最多的社交平台的个人资料页链接；',
          '账号归属权证明：',
          [
            '站外主人视角的个人主页截图（主人登录视角且带有粉丝数）；',
            '认证人手持站外后台合影；',
            '站外账号实名认证截图；',
            '站外平台发布的最近一条视频的编辑界面截图。',
          ],
        ],
        otherDesc: [
          '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等。',
        ],
      },
      {
        name: '其他达人',
        materialDesc: ['您需要填写您粉丝最多的社交平台的个人资料页链接', `账号归属权证明：`],
        id: '54',
        desc: [
          '您需要填写您粉丝最多的社交平台的个人资料页链接；',
          '账号归属权证明：',
          [
            '站外主人视角的个人主页截图（主人登录视角且带有粉丝数）；',
            '认证人手持站外后台合影；',
            '站外账号实名认证截图；',
            '站外平台发布的最近一条视频的编辑界面截图。',
          ],
        ],
        otherDesc: [
          '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等。',
        ],
      },
    ],
  },
  {
    name: '赛事',
    children: [
      {
        name: '赛事选手',
        id: '55',
        materialDesc: ['获奖证书/奖杯/奖牌'],
        desc: [
          '省市级及以上的获奖证书/奖杯/奖牌+奖项相关主流媒体报道链接；',
          '注：材料需体现获奖年份、赛事名称、获奖名次、真实姓名、证书颁发时间等信息，链接需体现赛事名称、获奖信息、真实姓名等信息。',
        ],
      },
    ],
  },
];

const decoratorMerge = (baseList: TDaren[], targetList: TDaren[]) =>
  _.map(baseList, (item: TDaren) => {
    const daren =
      _.find(targetList, (target: TDaren) => target.name === item.name) || ({} as TDaren);
    if (daren.children) item.children = decoratorMerge(item.children ?? [], daren.children ?? []);
    return {
      ...item,
      ...daren,
    };
  });

export const externalDarenList = decoratorMerge(baseDarenList, [
  {
    name: '达人',
    children: [
      {
        name: '娱乐达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
      },
      {
        name: '知识达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
      },
      {
        name: '艺术达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
      },
      {
        name: '游戏达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
      },
      {
        name: '其他达人',
        materialDesc: [
          '个人主页截图',
          '手持站外后台合影',
          '站外账号实名认证截图',
          '视频工程界面截图',
        ],
      },
    ],
  },
]);
