import { MessageOptions } from 'tdesign';
import message from 'common/components/Message';
import { ERROR_MSG, UNKNOWN_MISTAKE } from 'common/constants/Constants';

type TError = object | React.ReactNode;

export const handleError = (error: TError): any => {
  // 便于排查前端的一些error
  console.error(error);
  const errorCode = _.get(error, 'data.errCode');
  const errorMsg = _.get(error, 'data.errMessage');
  const httpStatus = _.get(error, 'status');

  if ([401, 403, 499].includes(httpStatus)) return null;

  if (ERROR_MSG.has(errorCode)) {
    const msg = ERROR_MSG.get(errorCode);
    return msg ? message.error({ content: msg }) : null;
  }

  if (ERROR_MSG.has(httpStatus)) {
    const msg = ERROR_MSG.get(httpStatus);
    return msg ? message.error({ content: msg }) : null;
  }

  if (errorMsg) {
    return message.error({ content: errorMsg });
  }

  if (_.isString(error) || React.isValidElement(error)) {
    return message.error({ content: error });
  }

  return message.error({ content: UNKNOWN_MISTAKE });
};

export const handleSuccess = (data: React.ReactNode | MessageOptions) => {
  if (_.isString(data) || React.isValidElement(data)) {
    message.success({ content: data });
  } else {
    message.success(data as MessageOptions);
  }
};
