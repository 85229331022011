/**
 * 工作环境常量变量初始化, 在所有页面代码之前调用
 * */

const DEFAULT_ENVIRONMENT_API = {
  EID_PUBLIC_CDN_URL: 'https://identity.tencent.com/public',
  EID_PUBLIC_IDP_SITE: 'https://account.tencent.com',
  EID_PUBLIC_ADMIN_SITE: 'https://workspace.tencent.com',
  EID_PUBLIC_PORTAL_SITE: 'https://workspace.tencent.com',
  EID_DINGTALK_IDENTITY_SITE: 'https://login.identity.tencent.com',
  EID_PUBLIC_GATEWAY_URL: 'https://api.account.tencent.com',
  EID_MEETING_SITE: 'https://meeting.tencent.com',
};

const changeFavicon = (link: string) => {
  if (!link) return;

  let favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;

  if (favicon) {
    favicon.href = link;
  } else {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = link;
    document.head.appendChild(favicon);
  }
};

function wrapState(action: string) {
  const raw = history[action];
  return function (this: any) {
    const wrapper = raw.apply(this, arguments);

    const e = new Event(action);

    _.set(e, 'stateInfo', arguments);

    window.dispatchEvent(e);

    return wrapper;
  };
}
// 修改原始定义
history.pushState = wrapState('pushState');
history.replaceState = wrapState('replaceState');

// NOTICE: 浏览器 tab logo 变更
changeFavicon(`${_.trimEnd(window.EID_PUBLIC_CDN_URL, '/')}/images/logo/favicon.ico`);

// 在 JSRuntime 或者 Utils 之前执行，所以不要使用 JSRuntime 和 Utils 中的方法

function getCustomDomain(url: URL, currentUrl: URL): string {
  const { host } = window.location;
  const { host: urlHost, protocol: urlProtocol, pathname } = url;
  if (!_.isString(urlHost)) return '';
  const companyIdentity = _.replace(host, currentUrl.host, '') || '';
  return `${urlProtocol}//${companyIdentity}${urlHost}${pathname}`;
}

function resetEIDEnv(options: { siteName: string }) {
  const { host, hostname } = window.location;
  const { siteName } = options;
  // codesign管理后台可以直接调当前oneID管理后台，他们跳转的域名还是identity.tencent.com，当访问该旧域名的时候，拿到一个EID_ADMIN_DOMAIN_URL参数，对应我们的新域名，直接跳转到我们的新域名
  if (window.EID_ADMIN_DOMAIN_URL) {
    const { hash, search } = window.location;
    const curHost = _.trimEnd(window.EID_ADMIN_DOMAIN_URL, '/');
    window.location.href = `${curHost}/${search}${hash}`;
    return;
  }

  // eslint-disable-next-line no-global-assign
  window = _.mergeWith(window, DEFAULT_ENVIRONMENT_API, (objValue, srcValue) => {
    if (!objValue) {
      return srcValue;
    }
    return objValue;
  });

  let siteURL;
  switch (siteName) {
    case 'IDP':
      siteURL = new URL(window.EID_PUBLIC_IDP_SITE);
      break;
    case 'PORTAL':
      siteURL = new URL(window.EID_PUBLIC_PORTAL_SITE);
      break;
    default:
      siteURL = new URL(window.EID_PUBLIC_ADMIN_SITE);
      break;
  }

  if (!siteURL?.href) return; // 流程上不应该有这种情况

  window.EID_PUBLIC_OFFICIAL_SITE = window.EID_PUBLIC_OFFICIAL_SITE || window.EID_PUBLIC_ADMIN_SITE;
  window.EID_PUBLIC_ORIGINAL_IDP_SITE = window.EID_PUBLIC_IDP_SITE;
  window.EID_PUBLIC_OAUTH2_URL = window.EID_PUBLIC_PRIVATE
    ? `${_.trimEnd(window.EID_PUBLIC_GATEWAY_URL, '/')}/oauth2`
    : _.replace(window.EID_PUBLIC_GATEWAY_URL, 'api', 'oauth2');

  window.EID_PUBLIC_ORIGINAL_PORTAL_SITE = window.EID_PUBLIC_PORTAL_SITE;
  window.EID_PUBLIC_ORIGINAL_ADMIN_SITE = window.EID_PUBLIC_ADMIN_SITE;

  window.EID_ACCOUNT_GATEWAY_URL = window.EID_ACCOUNT_GATEWAY_URL || window.EID_PUBLIC_GATEWAY_URL;
  window.EID_ACCOUNT_OAUTH2_URL = window.EID_PUBLIC_PRIVATE
    ? `${_.trimEnd(window.EID_ACCOUNT_GATEWAY_URL, '/')}/oauth2`
    : _.replace(window.EID_ACCOUNT_GATEWAY_URL, 'api', 'oauth2');

  // 针对SaaS做处理
  const isDefaultSaaSDomain = _.endsWith(hostname, siteURL.hostname) && !window.EID_PUBLIC_PRIVATE; // NOTICE 避免之后私有化域名与我们saas域名相同

  if (window.EID_PUBLIC_PRIVATE) {
    window.ONEID_PUBLIC_ORIGIN = false;
    window.ONEID_PUBLIC_ACCOUNT_DOMAIN = false;
    window.ONEID_PUBLIC_CUSTOM_DOMAIN = true;
  } else {
    window.ONEID_PUBLIC_ORIGIN = host === siteURL.host;
    window.ONEID_PUBLIC_ACCOUNT_DOMAIN = !window.ONEID_PUBLIC_ORIGIN && isDefaultSaaSDomain;
    window.ONEID_PUBLIC_CUSTOM_DOMAIN = !window.ONEID_PUBLIC_ORIGIN && !isDefaultSaaSDomain;
  }

  // 由企业标识组合成的SaaS域名
  if (window.ONEID_PUBLIC_ACCOUNT_DOMAIN) {
    // EID_PUBLIC_CDN_URL 保持不变
    // EID_PUBLIC_GATEWAY_URL 保持不变
    // EID_PUBLIC_OAUTH2_URL 与SaaS 版一致
    window.EID_PUBLIC_IDP_SITE = getCustomDomain(new URL(window.EID_PUBLIC_IDP_SITE), siteURL);
    window.EID_PUBLIC_PORTAL_SITE = getCustomDomain(
      new URL(window.EID_PUBLIC_PORTAL_SITE),
      siteURL,
    );
    window.EID_PUBLIC_ADMIN_SITE = getCustomDomain(new URL(window.EID_PUBLIC_ADMIN_SITE), siteURL);
  } else {
    // 2、企业自定义域名
    // EID_PUBLIC_CDN_URL 保持不变
    // window.EID_PUBLIC_GATEWAY_URL = `${currentSite}/gateway`; // TODO: 待定
    // window.EID_PUBLIC_OAUTH2_URL = `${currentSite}/oauth2`; // TODO: 待定
    // window.EID_PUBLIC_IDP_SITE = currentSite;
    // window.EID_PUBLIC_ADMIN_SITE = currentSite;
  }
}

resetEIDEnv({
  siteName: _.upperCase(RUNTIME_APP_NAME),
});
