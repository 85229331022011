import { AuthnApi as CommonAuthnApi } from 'common/api';
import {
  APP_EDITION_TYPE,
  TEMPLATE_MAP,
  AUTHENTICATION_METHOD,
  NOT_FOUND_ERROR,
  NO_AUTH,
  TEMPLATES,
  ACCOUNT_TYPE,
} from 'common/constants/Constants';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { AuthnApi, VendorApi } from 'idpBase/api';
import { IUserInfo } from 'common/constants/Types';
import { dataReport as beaconReport, APP_REPORT_KEY } from 'common/utils/dataReport';
import { DEFAULT_SETTINGS, REDIRECT_WHITE_LIST } from './constants/Constants';

class RootStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<RootStore>;
  public resetStore: () => void;
  public annotationOverrides = {
    isJustBrandDemoPC: false,
  };

  public isUserInfoLoading = true;
  public isFetchSettingsLoading = true;
  public userInfo: IUserInfo | null = null;
  // 租户的配置信息，包含订阅的应用、企业信息，自定义外观等
  public settings = DEFAULT_SETTINGS; // 数据请求在 /login 登录后以及 /sso_login 登录前
  public hasFetchSettings = false; // 是否获取过 settings
  public accountId = ''; // 有登录态或者自定义域名或者私有化才有值，不是始终有值
  public externalAppInfo = {
    logoUrl: '',
    name: '',
    redirectUri: '',
    id: '',
    edition: APP_EDITION_TYPE.COMPANY,
  };
  public externalAppType = '';

  public prevUrl = document.referrer; // 路由切换的前一个路由，给埋点数据上报使用
  public currentUrl = window.location.href; // 当前路由

  // admin系统外观参数,初始值
  public demoParams = {
    isFullScreen: !JSRuntime.isBrandDemoOrigin,
    isPc: !!JSRuntime.isBrandDemoOrigin,
  };

  public get isJustBrandDemoPC(): string {
    return (
      JSRuntime.isBrandDemoOrigin &&
      !m.get(this.demoParams, 'isFullScreen') &&
      m.get(this.demoParams, 'isPc')
    );
  }

  public get isDocsTemplate(): boolean {
    return m.get(this.settings, 'account.brands.template') === TEMPLATES.DOC;
  }

  public logout() {
    return CommonAuthnApi.logout(this.getIDPRedirectUrl());
  }

  private matchDomain(domain: string) {
    const { host } = window.location;
    const configUrl = new URL(JSRuntime.originalIdpSite);
    let currentDomain = _.replace(host, configUrl.host, '');
    currentDomain = _.trimEnd(currentDomain, '.');
    return currentDomain === domain;
  }

  public async init(options: { domainName: string }) {
    const searchObj = JSRuntime.parseLocationSearch();

    const hash = Utils.getPureHashPath(); // 这里获取的hash路径去掉了?或者多个/等干扰项

    if (searchObj?.code && searchObj.client && hash === '#/auth') {
      const [err] = await Utils.resolvePromise(
        AuthnApi.getAuthCodeSession({
          code: searchObj?.code,
          client: searchObj?.client,
        }),
      );
      if (err) {
        this.global.handleError(err); // 这里不需要return掉，如果没有种上cookie，直接走下面跳转到登录页
      }
    }

    this.getUserInfo()
      .then((userInfo) => {
        if (JSRuntime.isFullCustomDomain) {
          this.initSettings(options);
          return Promise.resolve(userInfo);
        }

        // 如果有登录态并且是自定义域名，那么更新当前url为最新domain
        const domain = m.get(userInfo, 'account.domain');
        // 个人中心 个人版不展示domain
        // 私有化域名不做任何跳转
        // profile在企业版版情况下需要拼接domain
        if (
          !JSRuntime.isPrivate &&
          hash === '#/profile' &&
          m.get(userInfo, 'account.accountType') === ACCOUNT_TYPE.COMPANY
        ) {
          // 有登录态且需要拼接domain时，doNotUseUserDomainSite中的页面允许使用与接口domain不同的domain
          if (!this.matchDomain(domain)) {
            // 跳转企业级域名
            Utils.safeRedirect(Utils.replaceUrlContent(JSRuntime.originalIdpSite, { domain }));
            return Promise.resolve(userInfo);
          }
        } else if (!hash.includes('#/sso_login/identity') && JSRuntime.isAccountDomain) {
          // 非私有化，且不是需要拼接domain的域名，域名中含有自定义domain时，跳回通用的域名
          Utils.safeRedirect(Utils.replaceUrlContent(JSRuntime.originalIdpSite));
          return Promise.resolve(userInfo);
        }
        this.initSettings(options);
        return Promise.resolve(userInfo);
      })
      .catch(() => {
        this.initSettings(options);
      });
  }

  public getUserInfo() {
    const { hash } = window.location;
    return AuthnApi.getUserInfo(JSRuntime.backEndApp || undefined)
      .then((res) => {
        // 接口成功之后，可以上报人员租户信息
        this.dataReport('e#oneid_total#all#get_self');

        const errCode = _.get(res, 'errCode');

        if (errCode === NO_AUTH && (hash.includes('profile') || hash.includes('auth'))) {
          Utils.handleNoAuth(window.location.href);
          return Promise.reject();
        }
        window.localStorage?.setItem('authenticationMethod', m.get(res, 'authenticationMethod'));

        this.updateStore({
          userInfo: m.get(res, 'data'),
          isUserInfoLoading: false, // NOTICE 如果没有其他跳转，那么loading结束，有其他跳转的时候，loading不结束
          // NOTICE 如果有值，可以在这里赋值一下，可以在有登录态的情况下避免redirectToLandingPage再去getUserInfo
          accountId: m.get(res, 'account.accountId'),
        });

        return Promise.resolve(m.get(res, 'data'));
      })
      .catch((err) => {
        this.dataReport('e#oneid_total#all#get_self');
        if (
          _.get(err, 'data.errCode') === NOT_FOUND_ERROR &&
          (hash.includes('profile') || hash.includes('auth'))
        ) {
          Utils.redirectToOriginalIdp();
          return Promise.reject();
        }
        // NOTICE 如果没有其他跳转，那么loading结束，有其他跳转的时候，loading不结束
        this.updateStore({
          isUserInfoLoading: false,
        });

        return Promise.reject(err);
      });
  }
  // 根据用户的登录方式来决定跳转的idp页面
  public redirectToIdpByUserMethod(...params: string[]) {
    Utils.safeRedirect(this.getIDPRedirectUrl(...params));
  }

  public getIDPRedirectUrl(qs?: string, path?: string): string {
    const method = window.localStorage.getItem('authenticationMethod');
    if (method === AUTHENTICATION_METHOD.ENTERPRISE) {
      return JSRuntime.getIdpSite(qs, path);
    }
    return JSRuntime.getOriginalIdpSite(qs, path);
  }

  // 获得第三方应用的配置信息
  public getExternalAppInfo(appType: string) {
    return VendorApi.getExternalAppInfoByType(appType)
      .then((res) => {
        this.updateStore({ externalAppInfo: res, externalAppType: appType });
        return res;
      })
      .catch((err) => {
        this.global.handleError(err);
        return Promise.reject(err);
      });
  }

  public async getSettings(params: { accountId: string }) {
    const [err, settings] = await Utils.resolvePromise(AuthnApi.getSettings(params));
    if (err) {
      this.global.handleError(err);
      return [err, settings];
    }
    const brands = m.get(settings, 'account.brands');
    const template = m.get(settings, 'account.brands.template');
    const styleInfo = m.get(TEMPLATE_MAP, template);
    const curBrands = _.mergeWith(brands, styleInfo, (tar: string, src: string) => tar || src);
    _.set(settings, 'account.brands', curBrands);
    this.updateStore({
      settings,
      hasFetchSettings: true,
    });
    return [err, settings];
  }

  public setSettingsBrands(brands: any) {
    mobx.set(this.settings.account, 'brands', brands);
  }
  /**
   * idp 登录侧所有登录成功后的跳转都走这里
   * (暂不处理个人版账号情况)
   * */
  public async redirectToLandingPage(options?: {
    accountId?: string;
    username?: string;
    skipSelect?: boolean; // 去开通会议/文档需要跳过选择账号
  }) {
    const opts = options || {};

    // TODO: 未开通文档应用，需要跳转到无权限页面

    // 1. 地址栏有回调地址 redirectUrl 则登录成功后优先跳转
    const redirectUrl = JSRuntime.thirdLandingPage;

    if (redirectUrl) {
      const currUrl = new URL(window.location.href);
      const originUrl = new URL(decodeURIComponent(redirectUrl));
      // TIPS：白名单判断标准，要么是腾讯域名，要么是浏览器url的host等于redirectUrl的host（主要解决私有化情况）
      const isWhiteList =
        REDIRECT_WHITE_LIST.some((reg) => reg.test(originUrl.host)) ||
        currUrl.host === originUrl.host;
      // 不在白名单中不跳转 直接去portal
      if (!isWhiteList) return Utils.redirectToPortal();
      const url = JSRuntime.isPrivate
        ? redirectUrl
        : JSRuntime.getOAuth2RedirectUrl(redirectUrl, opts.username || 'username', true);
      const redirect = !!opts.skipSelect ? JSRuntime.getSubscribeUrl(url) : url;
      if (redirect) {
        Utils.safeRedirect(redirect);
        Utils.postPopupRedirectMsg(redirect);
      }
      return;
    }

    // 2. 仅有一个套件应用且仅有一个vendorType=KIT的sso应用那么会直接触发这个 sso 应用的登录而不是跳转到 portal 页
    let settings = this.settings;
    if (!this.hasFetchSettings) {
      let accountId = opts.accountId || this.accountId;
      if (!accountId) {
        // 没有传 accountId 的动态获取一下
        const [err, userInfo] = await Utils.resolvePromise(AuthnApi.getUserInfo());
        const tempAccountId = m.get(userInfo, 'account.accountId');
        if (err || !tempAccountId) {
          // 理论不会到这一步，因为只有登录成功后才会触发redirectToLandingPage，这个时候一定有登录态
          return Utils.redirectToPortal();
        }
        accountId = tempAccountId;
      }

      const [err, realSettings] = await this.getSettings({ accountId: accountId! });

      settings = err ? {} : realSettings;
    }

    const suiteApps = m.get(settings, 'account.apps');

    if (!m.isArray(suiteApps) || suiteApps.length !== 1) return Utils.redirectToPortal();

    const [ssoErr, res] = await Utils.resolvePromise(AuthnApi.getSSOApps());

    if (ssoErr) {
      this.global.handleError(ssoErr);
      return Utils.redirectToPortal();
    }

    const ssoApps = m.get(res, 'apps');
    if (m.isArray(ssoApps) && ssoApps.length === 1 && m.get(ssoApps, '0.vendorType') === 'KIT') {
      const appId = m.get(ssoApps, '0.id');
      const redirectUrl = CommonAuthnApi.getLoginSSOLink(appId);
      return Utils.safeRedirect(redirectUrl);
    }

    return Utils.redirectToPortal();
  }

  // 仅有自定义域名及私有化环境可以通过如下方式获得 settings
  public async initSettings(options: { domainName: string }) {
    const { domainName } = options;
    this.updateStore({ isFetchSettingsLoading: true });

    let accountId = this.accountId || '';
    const hash = Utils.getPureHashPath();
    if (JSRuntime.isCustomDomain && (!accountId || hash.includes('#/sso_login/identity'))) {
      // 自定义域名通过当前域名获得 AccountId
      const [err, res] = await Utils.resolvePromise(AuthnApi.loginByEmailOrDomain({ domainName }));
      /**
       * 报错表示当前域名没法查出对应企业， 这个时候有两种情况
       * 1. 因为不是在自定义域名及私有化环境下，先不去处理
       * 2. 企业下没有认证源 E0010042 这种情况交给下一层自己处理
       */
      if (err) {
        this.updateStore({ isFetchSettingsLoading: false });
        console.error(err);
        return;
      }
      accountId = _.get(res, 'accountId');
    }

    if (!accountId) {
      this.updateStore({ isFetchSettingsLoading: false });
      return;
    }

    this.getSettings({ accountId }).finally(() => {
      this.updateStore({ isFetchSettingsLoading: false, accountId });
    });
  }

  // 重置accountId、settings等信息
  public resetAccount() {
    this.updateStore({
      accountId: '',
      settings: DEFAULT_SETTINGS,
      hasFetchSettings: false,
    });
  }

  public updateDataReportUrl() {
    if (this.currentUrl === window.location.href) return;
    this.updateStore({
      prevUrl: this.currentUrl,
      currentUrl: window.location.href,
    });
  }

  // 数据上报
  public dataReport(eventName: string, params?: Record<string, any>) {
    const { account, accountUser } = this.userInfo || {};

    beaconReport(
      eventName,
      {
        alias_id: m.get(accountUser, 'aliasId'),
        union_id: m.get(accountUser, 'unionId'),
        account_uid: m.get(account, 'accountId'),
        account_type: m.get(account, 'accountType'),
        account_name: m.get(account, 'companyName'),
        prev_url: this.prevUrl || document.referrer || JSRuntime.thirdLandingPage, // NOTICE 在无登录态时，手动输入管理后台/工作台地址，会跳转到登录相关页面，prev_url是当前路径上的redirectUrl
      },
      params,
      {
        [APP_REPORT_KEY.WEMEET]: JSRuntime.isTencentMeeting,
      },
    );
  }

  // 信息加密
  public async encryptRequest(params: { subject?: string; password: string }) {
    const [encryptErr, encrypt] = await Utils.resolvePromise(CommonAuthnApi.getPwdEncryptKey());
    if (encryptErr) return '';
    return Utils.encryptObj(encrypt?.key, encrypt?.kid, params);
  }
}

export default RootStore;
