import 'common/utils/initEnv';
import 'common/store/initMobxConfig';
import 'common/styles/main.scss';
import 'tdesign-mobile-react/es/style/index.css'; // 少量公共样式
import './static/css/main.scss';
import 'animate.css';
import { HashRouter } from 'react-router-dom';
import Aegis from '@tencent/aegis-web-sdk';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { beforeRequest } from 'common/utils/aegisReport';
import globalStore, { Provider } from 'idpBase/store';
import RootContainer from './modules/root';

if (JSRuntime.isProductSite) {
  const aegis = new Aegis({
    id: 'e7XnpFlqkXjYOVWxjx', // 上报 id
    uin: Utils.getCookie('web_uid'), // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    beforeRequest,
  });
  _.merge(window, { aegis });
}

ReactDOM.render(
  <Provider store={globalStore}>
    <React.Suspense fallback={<div>loading...</div>}>
      <HashRouter>
        <RootContainer />
      </HashRouter>
    </React.Suspense>
  </Provider>,
  document.getElementById('root'),
);
