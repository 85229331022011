import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator';
import APINames from '../APINames';

class AccountApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNT);

  // 获取企微绑定信息
  getWeComBindInfo(idpType: string) {
    return this.requestInstance.get(['ssoIdp', `${idpType}`]);
  }

  // 获得租户相关设置
  getTenantSettings() {
    return this.requestInstance.get('settings');
  }

  // 获取企业行业类型列表
  getIndustryTypes() {
    return this.requestInstance.get('industryTypes');
  }

  // 获取企业规模列表
  getEnterpriseSizes() {
    return this.requestInstance.get('enterpriseSizes');
  }

  getDomainConfig() {
    return this.requestInstance.get('');
  }
}
export default new AccountApi();
