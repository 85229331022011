/**
 * 公用方法
 * */

import { lib, MD5 } from 'crypto-js';
import Parse from 'html-react-parser';
import { JSEncrypt } from 'jsencrypt';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import _ from 'lodash';
import xss from 'xss';
import { uuidv4 } from '@tencent/web-base';
import RolesApi from 'common/api/servicesV3/RolesApi';
import { TApiError } from 'common/apiRequest/Request';
import Dialog from 'common/components/Dialog';
import {
  AUTHENTICATION_METHOD,
  BACKGROUND_TYPES,
  DEBOUNCE_DEFAULT_SECOND,
  INVALID_CONTENT_ERROR_CODE,
  LOGIN_POSITIONS,
  POSITIONS,
} from 'common/constants/Constants';
import { IBrands } from 'common/constants/Types';
import JSRuntime from 'common/utils/JSRuntime';
import publicStyles from 'common/styles/eid-variables-in-js.module.scss';

class Utils {
  public uniteClass(...args: any[]) {
    let classname = '';
    args.forEach((arg) => {
      if (typeof arg === 'string') {
        classname += ` ${arg}`;
      }
    });
    return classname.trim();
  }

  public noop() {}

  private getImageUrl(path: string, url: string): string {
    if (!url || !_.isString(url)) return url;

    if (/^blob:/.test(url) || /^https?:/.test(url)) return url;

    if (url.startsWith(`${JSRuntime.cdnUrl}/`)) return url;

    const trimPath = _.trim(path, '/');
    const pathArr = [JSRuntime.cdnUrl, 'images'];
    if (trimPath) pathArr.push(trimPath);
    pathArr.push(_.trimStart(url, '/'));
    return pathArr.join('/');
  }

  // 其他静态图片。约定，图片都包含完整路径包括扩展名
  public renderImage(url: string) {
    return this.getImageUrl('/web', url);
  }

  // 应用logo图片。约定，图片都包含完整路径包括扩展名
  public renderAppLogo(url: string): string {
    return this.getImageUrl('/', url);
  }

  // EID logo图片。约定，图片都包含完整路径包括扩展名
  private renderBrandLogo(url: string): string {
    return this.getImageUrl('/logo', url);
  }

  public renderLogo() {
    return this.renderBrandLogo('eid-logo.png');
  }

  public renderBrand() {
    return this.renderBrandLogo('eid-brand.png');
  }

  public renderBrandVertical() {
    return this.renderBrandLogo('eid-brand-vertical.png');
  }

  public renderCompanyLogo(url: string) {
    return this.renderImage(url || 'default-company-logo.png');
  }

  public resolvePromise(promise: Promise<any>): Promise<any> {
    return promise.then((data) => [null, data]).catch((err) => [err, null]);
  }

  // 获取纯粹的hash路径 去除?和多重/等干扰
  public getPureHashPath(): string {
    const { hash = '' } = window.location;
    return (
      hash
        .split('?')?.[0]
        ?.split('/')
        .filter((item) => item)
        ?.join('/') || ''
    );
  }

  // 可设置高亮颜色，忽略大小写
  public parseHighLightKeywords(
    keywords: string,
    str: string,
    color = publicStyles.tdFontColorLink,
  ) {
    function escapeHtml(unsafe: string) {
      return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    }

    if (!keywords) return str;
    str = escapeHtml(str);
    const usefulStr = xss.filterXSS(str) || '';
    const reg = new RegExp(keywords, 'ig');
    const highLightStr = usefulStr.replace(
      reg,
      (matchValue) =>
        `<span style="color:${color}; text-decoration: underline">${matchValue}</span>`,
    );

    return Parse(highLightStr);
  }

  // 页面注入脚本
  public requireJS(url: string, props?: object) {
    return new Promise((resolve, reject) => {
      const scriptElement: any = document.createElement('script');
      scriptElement.src = url;
      scriptElement.type = 'text/javascript';
      Object.keys(props || {}).forEach((p: string) => {
        scriptElement[p] = _.get(props, p);
      });
      if (scriptElement.readyState) {
        // only required for IE <9
        scriptElement.onreadystatechange = () => {
          if (scriptElement.readyState === 'loaded' || scriptElement.readyState === 'complete') {
            scriptElement.onreadystatechange = null;
            resolve(undefined);
          }
        };
      } else {
        // Others
        scriptElement.onload = () => {
          resolve(undefined);
        };
        scriptElement.onerror = () => {
          reject();
        };
      }
      const head = _.get(document.getElementsByTagName('head'), [0]);
      if (!head) {
        reject();
      } else {
        head.appendChild(scriptElement);
      }
    });
  }

  public safeParseJSON(json: string): { [key: string]: any } {
    if (!_.isString(json)) return {};
    let result = {};
    try {
      result = JSON.parse(json);
    } catch (e) {
      result = {};
    }

    return result;
  }

  public safeRedirect(url: string) {
    if (!url) return;

    const link = document.createElement('a');
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('href', url);
    // Tips: 不加这行，在firefox上执行link.click()时无反应
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  public getOriginHost(url: string) {
    const configUrl = new URL(url);
    let ret = configUrl.host;
    [
      window.EID_PUBLIC_ORIGINAL_ADMIN_SITE,
      window.EID_PUBLIC_ORIGINAL_IDP_SITE,
      window.EID_PUBLIC_ORIGINAL_PORTAL_SITE,
    ].forEach((originUrl) => {
      const originUrlConfig = new URL(originUrl);
      if (_.includes(configUrl.host, originUrlConfig.host)) {
        ret = originUrlConfig.host;
      }
    });
    return ret;
  }
  public replaceUrlContent(
    url: string,
    config: {
      domain?: string; // TIPS:如果domain是空字符串会将domain设置为空
      host?: string;
      search?: string;
      pathname?: string;
      hash?: string;
      protocol?: string;
    } = {},
  ) {
    if (!url) return '';
    const configUrl = new URL(url);

    const { pathname, protocol, search, hash } = window.location;

    configUrl.host = this.getOriginHost(url);
    if (_.isString(config.domain) && config.domain) {
      configUrl.host = `${config.domain}.${configUrl.host}`;
    }
    // 域名部分不能设置成空字符串，其他部分允许设置成空字符串
    return `${config.protocol || protocol}//${config.host || configUrl.host}${
      _.has(config, 'pathname') ? config.pathname : pathname
    }${_.has(config, 'search') ? config.search : search}${
      _.has(config, 'hash') ? config.hash : hash
    }`;
  }

  // 跳转到经过 自定义域名处理过的 idp 地址
  public redirectToIdp(qs?: string, path?: string) {
    this.safeRedirect(JSRuntime.getIdpSite(qs, path));
  }

  // 跳转到 SaaS 版的 idp 地址
  public redirectToOriginalIdp(qs?: string, path?: string) {
    this.safeRedirect(JSRuntime.getOriginalIdpSite(qs, path));
  }

  public redirectToPortal(qs?: string, path?: string) {
    this.safeRedirect(
      `${JSRuntime.accountOauth2Site}/v1/auth?goto=${JSRuntime.getPortalSite(qs, path)}`,
    );
  }

  public redirectToAdmin(qs?: string, path?: string) {
    this.safeRedirect(JSRuntime.getAdminSite(qs, path));
  }

  public redirectToIsv(qs?: string, path?: string) {
    this.safeRedirect(JSRuntime.getIsvSite(qs, path));
  }

  public redirectToOfficial() {
    this.safeRedirect(JSRuntime.officialSite);
  }

  /**
   * 将utc时间转换为moment格式
   * TIPS: 后端返回的utc时间会统一成时间戳，现在需要同时兼容。
   * @param utcTime 10位时间戳 或 13位时间戳 或 'YYYY-MM-DD HH:mm:ss'
   */
  public utcToLocale(utcTime: number | string) {
    try {
      let time = utcTime;
      if (typeof utcTime === 'number' && String(utcTime).length === 10) {
        time = utcTime * 1000;
      }
      return moment.utc(time).local();
    } catch (err) {
      return moment();
    }
  }

  public safeDownload = _.debounce((url: string) => {
    if (!url) return;
    const link = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    // 浏览器会自动获取文件名
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, DEBOUNCE_DEFAULT_SECOND);

  public computedOverflowLabel = (prevLabel = '', limit: number) => {
    let len = 0;
    let nextLabel = '';
    let label = '';
    if (_.isString(prevLabel)) {
      label = prevLabel;
    } else if (_.isNumber(prevLabel) && !_.isNaN(prevLabel)) {
      label = `${prevLabel}`;
    }
    const hasOverflow = label.split('').some((str) => {
      const isCN = /[\u4e00-\u9fa5]/gm.test(str);
      if (len < limit) nextLabel += str;
      const isOverflow = len >= limit;
      len += isCN ? 2 : 1;
      return isOverflow;
    });

    return { hasOverflow, label: hasOverflow ? `${nextLabel}...` : nextLabel };
  };

  public async postPopupRedirectMsg(url: string) {
    JSRuntime.popupPostMessage({
      type: 'EIDAuthnSuccess',
      payload: { redirectUrl: url },
    });
  }

  public getFileMD5(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        resolve(MD5(lib.WordArray.create(reader.result as any)).toString() || '');
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  }
  public fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  // 用于网络地址或者 blobUrl 字符串转为 Blob 对象 axios@0.27 有bug 所以用的 XMLHttpRequest
  public urlToBlob(url: string) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.open('GET', url, true);
      req.responseType = 'blob';

      req.addEventListener('load', () => {
        const blob = req.response;
        resolve(blob);
      });

      req.addEventListener('error', (e) => {
        reject(e);
      });

      req.send();
    });
  }

  public phoneHide(phone?: string) {
    if (!_.isString(phone)) return;
    const hidePhone = phone.replace(/(\d{3})\d{4}(\d{0})/, '$1****$2');
    return hidePhone;
  }

  public focusFirstInput() {
    const inputs = document.getElementsByTagName('input');
    if (inputs && inputs.length > 0) {
      const firstInput = inputs[0];
      firstInput.focus();
    }
  }

  public copyText(text: string) {
    if (!text) {
      return;
    }
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    // 执行 copy 命令
    if (document.execCommand('copy')) {
      document.execCommand('copy');
    }
    document.body.removeChild(input);
  }

  /**
   * 生成随机密码
   * 密码规则：包含0-9数字、大小写字母，英文字符，四种类型都必须至少包含一个；
   * @param len 密码长度
   */
  public genRandomPassword = (len: number): string => {
    if (len < 3) {
      console.error('Random password length MUST at least 3!');
      return '';
    }
    // ASCII code: 48 => 0
    const digits = Array(10)
      .fill('')
      .map((x: string, index: number) => String.fromCharCode(index + 48));
    // ASCII code: 65 => A
    const upperLetters = Array(26)
      .fill('')
      .map((x: string, index: number) => String.fromCharCode(index + 65));
    // ASCII code: 97 => a
    const lowerLetters = Array(26)
      .fill('')
      .map((x: string, index: number) => String.fromCharCode(index + 97));
    const symbols = '!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~'.split('');
    const allLetters = [...digits, ...upperLetters, ...lowerLetters, ...symbols];
    const randomLen = len - 4;
    // 除了必须包含的4种类型字符，其他随机获取
    const passwordArr = _.sampleSize(allLetters, randomLen);
    // 分别追加三种类型的字符，保证符合密码规则
    passwordArr.push(
      digits[Math.floor(Math.random() * 10)],
      upperLetters[Math.floor(Math.random() * 26)],
      lowerLetters[Math.floor(Math.random() * 26)],
      symbols[Math.floor(Math.random() * 31)],
    );
    // 打乱字符
    return _.shuffle(passwordArr).join('');
  };

  /**
   * 根据配置数据生成自定义样式style
   * @param brands 自定义样式信息
   */
  public genCustomStyle = (brands: IBrands) => {
    const { backgroundType, position, imageUrl, fillingType, color } = brands;
    const style: { [key: string]: string } = {};

    if (position && position !== LOGIN_POSITIONS.DEFAULT) {
      style.justifyContent = POSITIONS[position];
    }

    if (backgroundType === BACKGROUND_TYPES.IMAGE && imageUrl) {
      const backgroundStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: fillingType?.toLowerCase() || 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };
      Object.assign(style, backgroundStyle);
    }

    if (backgroundType === BACKGROUND_TYPES.COLOR && color) {
      const backgroundStyle = {
        backgroundImage: 'none',
        backgroundColor: color,
      };
      Object.assign(style, backgroundStyle);
    }

    return style;
  };

  // 增加不带hash值的className， eg: 'SSOLogin_wrapper_hnMqn' =>  'SSOLogin_wrapper_hnMqn SSOLogin_wrapper'
  public addUnHashedClass = (classNameMap: { [key: string]: string }) =>
    Object.keys(classNameMap).reduce((prev: { [key: string]: string }, className: string) => {
      const prevClassName = classNameMap[className] || '';
      const newClassName = prevClassName.split('_').slice(0, 2).join('-');
      if (!_.isString(prevClassName)) return { ...prev, [className]: prevClassName };

      return { ...prev, [className]: `${prevClassName} ${newClassName}` };
    }, {});

  // 用于切换 样式 或者注入 样式
  resetThemeStyle(styleString: string) {
    const preStyleNode = document.querySelector('style#eid-theme-style');
    if (
      preStyleNode?.parentElement &&
      _.isFunction(_.get(preStyleNode, 'parentElement.removeChild'))
    ) {
      preStyleNode.parentElement.removeChild(preStyleNode);
    }
    const style = document.createElement('style');
    style.innerHTML = styleString;
    style.id = 'eid-theme-style'; // 不要改这个值
    document.head.appendChild(style);
  }

  clearSessionStore() {
    sessionStorage.clear();
  }

  // 查看错误响应中是否包含 安全合规-内容风险 检查出的错误，需要处理的response格式如下：
  // {
  //   "errCode": "E0010217",
  //   "errMessage": "[{\"msg\":\"内容不合法，请修改后重新保存\",\"desc\":\"name\"},{\"msg\":\"内容不合法，请修改后重新保存\",\"desc\":\"username\"}]",
  //   "id": "c18df1f23e9579a7f605aec32464de37"
  // }
  public hasInvalidContent(err: { data: { errCode: string; errMessage: string } }) {
    return err?.data?.errCode === INVALID_CONTENT_ERROR_CODE;
  }
  public formatInvalidContent(err: { data: { errCode: string; errMessage: string } }) {
    const getInvalidKeyAndMsg = (errMessage: string) => {
      const invalidData = {};
      const firstErrKey = ''; // 第一个错误字段key，用于滚动到表单处

      const result = this.safeParseJSON(errMessage) || [];
      if (_.isArray(result)) {
        result.forEach((item: { msg: string; desc: string }) => {
          if (item?.desc && item?.msg) {
            invalidData[item.desc] = [{ type: 'error', message: item.msg }];
          }
        });
      }
      return { invalidData, firstErrKey };
    };

    if (err?.data?.errCode === INVALID_CONTENT_ERROR_CODE) {
      const { invalidData, firstErrKey } = getInvalidKeyAndMsg(err?.data?.errMessage);
      return {
        invalidData,
        firstErrKey,
      };
    }
    return { invalidData: {}, firstErrKey: '' };
  }

  // 注：测试号段
  // 1、国内手机号：+86 160为前缀，11位，例+86 16000911111
  // 2、海外手机号：+852 1239为前缀，10位，例+852 1239111111
  public isValidPhoneNumber(phoneNum: string) {
    if (!phoneNum) return false;

    const reg = /(^\+86 160[0-9]{8}$)|(^\+852 1239[0-9]{6}$)/;

    return isValidPhoneNumber(phoneNum) || reg.test(phoneNum);
  }

  public addAccountChangeListener(getCurrentUnionId: () => string | undefined) {
    // 移动端不轮询
    if (
      JSRuntime.isMobileDevice ||
      JSRuntime.isWechat ||
      JSRuntime.isWxworkBrowser ||
      JSRuntime.isWechatMiniProgram
    )
      return;

    try {
      if (!window.sessionWorker) {
        window.sessionWorker = new SharedWorker(
          // @ts-ignore
          new URL('./sessionWorker.js', import.meta.url),
        );
      }

      // 此时已经连接上worker
      window.sessionWorker?.port?.postMessage(JSRuntime.accountOauth2Site);

      window.sessionWorker.port.onmessage = (e: Event) => {
        const currUnionId = getCurrentUnionId();
        if (!currUnionId) return;

        const sub = _.get(e, 'data.sub');
        const active = _.get(e, 'data.active');
        // NOTICE 登录态失效或者是切换账户导致信息变更，告知用户。
        if (!active || (sub && sub !== currUnionId)) {
          window.sessionWorker?.port?.close();
          Dialog.alert({
            header: '登录态发生变更',
            body: '当前登录身份已经发生变更，页面会在本窗口关闭后刷新',
            confirmBtn: '立即刷新',
            onConfirm: () => {
              // 弹窗消失有300-400ms动效，延迟一会儿刷新页面效果比较好
              setTimeout(() => {
                window.location.reload();
              }, 350);
            },
            onClose: () => {
              setTimeout(() => {
                window.location.reload();
              }, 350);
            },
          });
        }
      };
    } catch (err) {
      /* empty */
    }

    return () => {
      window.sessionWorker?.port?.close();
    };
  }

  // 加密信息
  public encryptObj(key: string, kid: string, obj: Record<string, any>) {
    const objJson = JSON.stringify({ ...obj, nonce: uuidv4() });
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(key);
    const encryptStr = encrypt.encrypt(objJson) || ''; // 加密
    return `${encryptStr}.${kid}`;
  }

  // 获取cookie
  public getCookie = (key: string) => {
    const cookieStr = document.cookie;
    if (_.isString(cookieStr)) {
      const cookieArr = cookieStr?.split(';');
      for (let i = 0; i < cookieArr.length; i++) {
        const c = cookieArr[i].trim();
        if (c.indexOf(key) == 0) {
          return c.substring(key.length + 1, c.length);
        }
      }
    }

    return '';
  };

  // 接口成功信息
  public aegisSuccessInfo = (response: Record<string, any>) => {
    const config = m.get(response, 'config');
    const requestUrl = `${config?.baseURL}${config?.url}`;
    const xTraceId = response?.headers?.['x-trace-id'] || '';
    const reportHeaders = {
      'x-trace-id': xTraceId,
    };
    const reportLogData = `
    【method】=> ${response?.config?.method},
    【url】=> ${requestUrl},
    【status】=> ${response?.status},
    【data】=> ${JSON.stringify(response?.config?.data)},
    【params】=> ${JSON.stringify(response?.config?.params)},
    【response】=> ${JSON.stringify(response?.data)},
    【headers】=> ${JSON.stringify(reportHeaders)},`;

    return reportLogData;
  };

  public aegisErrorInfo = (error: TApiError) => {
    const { response } = error;
    const requestUrl = `${response?.config?.baseURL}${response?.config?.url}`;
    const xTraceId = response?.headers?.['x-trace-id'] || '';
    const message = m.get(response, 'data.errMessage') || error.message || '未知错误';
    const errMsg = `Response Error: url => ${requestUrl};status => ${response?.status};msg => ${message};x-trace-id: ${xTraceId};`;
    return errMsg;
  };

  public handleNoAuth = (landingPage: string) => {
    if (JSRuntime.thirdLandingPage) {
      this.safeRedirect(JSRuntime.thirdLandingPage);
    } else {
      const method = window.localStorage.getItem('authenticationMethod');
      const redirectUrl = window.location.href;
      const redUrl = redirectUrl ? `?redirectUrl=${window.encodeURIComponent(landingPage)}` : '';
      if (method === AUTHENTICATION_METHOD.ENTERPRISE) {
        this.redirectToIdp(redUrl);
      } else {
        this.redirectToOriginalIdp(redUrl);
      }
    }
  };

  /**
   * 拍平树形结构
   * @param list 数据源
   * @param key 获取子节点集合的key
   * @returns Array<any>
   */
  public flattenTree = <T = any>(list: Array<T> = [], key = 'children'): Array<T> => {
    return list.flatMap((item: T) => {
      const children = (item as any)?.[key] || [];
      return [item, ...this.flattenTree(children)];
    });
  };

  /**
   * 根据 action_group_code 从权限数据中获取无权限的报错信息
   * @param agc string
   * @return agc对应的权限错误消息
   */
  public getPermsErrMsgByCode = async (agc: string): Promise<string> => {
    const { action_groups = [] } = await RolesApi.getActionGroups();

    const flatList = this.flattenTree(action_groups);

    let msg = '';
    const paths = agc.split('/');
    while (paths.length) {
      const path = paths.join('/');
      const authInfo = flatList.find((item) => item.action_group_code === path);
      if (authInfo?.name) {
        msg = authInfo.name;
        break;
      }
      paths.pop();
    }
    return `无${msg}访问权限，请联系管理员`;
  };
}

export default new Utils();
