import { getBlockedErrMsg } from 'common/components/BlockedErrMsg';

export enum EMAIL_VERIFY_CODE_SCENE {
  EMAIL_LOGIN = 'email_login',
  PASSWORD_FORGOT = 'password_forgot',
}

export enum CREATE_CARD_TYPE {
  CREATE_PERSONAL_ACCOUNT,
  CREATE_ORG_ACCOUNT,
  LOGIN_ACCOUNT,
}

export const CODE_DESIGN = 'codesign';
// 账号被封禁时错误码
export const BLOCKED_ERROR_CODE = 'E0010344';

export const BLOCKED_ERROR_MSG = {
  [BLOCKED_ERROR_CODE]: getBlockedErrMsg(),
};

// export const MAINLAND_PHONE_REGEXP = /^\+86\s/; // 匹配以'+86 '开头的手机号

export enum PASSWORD_LOGIN_TYPE {
  EMAIL = 'email',
  MOBILE = 'mobile',
}

export enum AUTH_TYPE {
  UNKNOWN = '0', // 获取是否认证过
  KOL = '1', // 达人
  OCCUPATION = '2', // 职业
}

export const RESET_PWD_ERROR_CODE = ['E0010381', 'E0010382', 'E0010383', 'E0010384'];
