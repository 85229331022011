export const beforeRequest = (data: Record<string, any>) => {
  const logType = m.get(data, 'logType');
  const logs = m.get(data, 'logs');
  if (logType === 'log' && logs?.level === '16' && logs?.msg?.indexOf('res status: 4') > -1) {
    return false;
  }

  const ignores = [
    // 数据上报接口
    'dr.account.tencent.com',
    // aegis上报接口
    'aegis.qq.com',
  ];
  const msg = logs.msg ?? logs.url;
  if (ignores.some((ignore) => msg?.includes(ignore))) {
    return false;
  }

  // 接口200成功，但是有errCode情况不上报
  if (isSpeedLog(logType) && logs?.status === 200 && !!logs?.ret) {
    return false;
  }

  return data;
};

// '接口和静态资源测速'
const isSpeedLog = (logType: string) => {
  return logType === 'speed';
};
