import BaseApi from 'common/apiRequest/apis/BaseApi';
import { CaptchaPack } from 'common/apiRequest';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/User';

class AuthnApi extends BaseApi {
  public requestInstance = new Request(APINames.USER);

  // 注册码获取
  getRegisterCode(mobile: string) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['signup', 'sms:send'], {
        mobile,
        captchaVerification,
      }),
    );
  }

  // 注册码验证 + 账号注册
  register(params: { verificationSessionId: string; verificationCode: string; app?: string }) {
    return new CaptchaPack().run((captchaVerification) =>
      this.requestInstance.post(['signup'], {
        ...params,
        captchaVerification,
      }),
    );
  }
}
export default new AuthnApi();
