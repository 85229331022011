/**
 * store 汇总, 并建立 store 间通信的桥梁
 * */
import enhanceStore from 'common/store/enhanceStore';
import createContext from 'common/store/createStoreContext';
import { disposeOnUnmount } from 'common/store/disposeOnUnmount';
import { handleError, handleSuccess } from './ErrorHandler';

import SocialLoginStore from '../../modules/socialLogin/SocialLoginStore';
import ForgetPwdStore from '../../modules/forgetPwd/ForgetPwdStore';
import RegisterStore from '../../modules/register/RegisterStore';
import RootStore from '../../modules/root/RootStore';
import BindTenantStore from '../../modules/bindTenant/BindTenantStore';
import AppAuthorization from '../../modules/appAuthorization/AppAuthorizationStore';
import ProfileStore from '../../modules/profile/personalCenter/ProfileStore';
import InvitationStore from '../../modules/invitation/InvitationStore';
import AccountManageStore from '../../modules/profile/accountManage/AccountManageStore';

// 自然人 identity
import SSOLoginStore from '../../modules/sso/ssoIdentity/ssoLoginBase/SSOLoginStore';
import SSOLoginSetPwdStore from '../../modules/sso/ssoIdentity/setPassword/SetPasswordStore';
import SSOLoginResetPwdStore from '../../modules/sso/ssoIdentity/resetPassword/ResetPasswordStore';
// sso登录 (待改名)
import SSOLoginBaseStore from '../../modules/sso/ssoLogin/ssoLoginStore';

// select页面
import SelectAccountStore from '../../modules/selectAccount/SelectAccountStore';

// 登录
import LoginBaseStore from '../../modules/login/base/BaseStore';
import LoginMobileStore from '../../modules/login/base/modules/mobile/LoginMobileStore';
import EmailLoginStore from '../../modules/login/base/modules/email/EmailLoginStore';
import MeetingQrCodeStore from '../../modules/login/base/modules/meetingQrCode/MeetingQrCodeStore';
import MeetingWecomQrCodeStore from '../../modules/login/base/modules/meetingWecomQrCode/MeetingWecomQrCodeStore';
import ChooseAccountStore from '../../modules/login/chooseAccount/ChooseAccountStore';
import LoginInvitationStore from '../../modules/login/invitation/InvitationStore';

// 个人认证
import InformationStore from '../../modules/auth/information/InformationStore';
import ProgressStore from '../../modules/auth/progress/ProgressStore';
import AuthorizationStore from '../../modules/auth/auth/AuthorizationStore';

class GlobalStore {
  public sso = {
    ssoLoginStore: enhanceStore(new SSOLoginBaseStore(), this),
    ssoIdentity: {
      ssoLoginStore: enhanceStore(new SSOLoginStore(), this),
      ssoLoginSetPwdStore: enhanceStore(new SSOLoginSetPwdStore(), this),
      ssoLoginResetPwdStore: enhanceStore(new SSOLoginResetPwdStore(), this),
    },
  };

  public login = {
    loginBaseStore: enhanceStore(new LoginBaseStore(), this),
    chooseAccountStore: enhanceStore(new ChooseAccountStore(), this),
    invitationStore: enhanceStore(new LoginInvitationStore(), this),
    meetingQrCodeStore: enhanceStore(new MeetingQrCodeStore(), this),
    meetingWecomQrCodeStore: enhanceStore(new MeetingWecomQrCodeStore(), this),
    loginMobileStore: enhanceStore(new LoginMobileStore(), this),
    emailLoginStore: enhanceStore(new EmailLoginStore(), this),
  };

  public socialLoginStore = enhanceStore(new SocialLoginStore(), this);
  public forgetPwdStore = enhanceStore(new ForgetPwdStore(), this);
  public registerStore = enhanceStore(new RegisterStore(), this);
  public rootStore = enhanceStore(new RootStore(), this);
  public bindTenantStore = enhanceStore(new BindTenantStore(), this);
  public appAuthorizationStore = enhanceStore(new AppAuthorization(), this);
  public profileStore = enhanceStore(new ProfileStore(), this);
  public invitationStore = enhanceStore(new InvitationStore(), this);
  public accountManageStore = enhanceStore(new AccountManageStore(), this);
  public selectAccountStore = enhanceStore(new SelectAccountStore(), this);

  public auth = {
    informationStore: enhanceStore(new InformationStore(), this),
    progressStore: enhanceStore(new ProgressStore(), this),
    authorizationStore: enhanceStore(new AuthorizationStore(), this),
  };

  public handleError = handleError;
  public handleSuccess = handleSuccess;
}

export type TGlobalStore = GlobalStore;

const { Provider, StoreContext, useStore, inject, convert } = createContext<TGlobalStore>();

export * from 'common/store/baseActions';
export { Observer, useLocalObservable } from 'mobx-react';
export { Provider, StoreContext, useStore, inject, convert, disposeOnUnmount };

export default new GlobalStore();
