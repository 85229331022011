import BaseApi from 'common/apiRequest/apis/BaseApi';
import JSRuntime from 'common/utils/JSRuntime';
import { AUTH_TYPE } from 'idpBase/Constants';
import APINames from '../APINames';
import { Request } from '../apiConcentrator/Person';
import { allList, baseDarenList } from '../MockData';

class AccountsApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNT);
  public accountInstance = new Request(APINames.ACCOUNT, {
    baseURL: `${JSRuntime.oauth2Site}/v1`,
  });

  /**
   * 获取人脸识别token和url信息
   * @param params
   * @returns
   */
  getFaceAuthInfo(params: Record<string, any>) {
    return this.requestInstance.post(
      ['personal', 'certify', 'face_auth', 'get_face_auth_info'],
      params,
    );
  }

  // 获取职业信息
  getCareerInfo(authType: AUTH_TYPE) {
    switch (authType) {
      case AUTH_TYPE.OCCUPATION:
        return Promise.resolve(allList);
      case AUTH_TYPE.KOL:
        return Promise.resolve(baseDarenList);
      default:
        return Promise.resolve([]);
    }
  }

  /**
   * 认证信息授权
   * @param params
   * @returns
   */
  authorize() {
    return this.requestInstance.put(['personal', 'certify', 'authorize'], {
      app_type: JSRuntime.appFromUrl,
    });
  }

  /**
   * 同步认证信息到个人中心
   * @param params
   * @returns
   */
  syncAuthInfo() {
    return this.requestInstance.put(['personal', 'certify', 'profile', 'sync']);
  }

  /**
   * 获取上传图片token
   * @param
   * @returns
   */
  getUploadToken(params: {
    job_certify_id: string;
    upload_resource: string;
    resource_type: string;
  }) {
    return this.requestInstance.post(['personal', 'certify', 'upload_auth'], params);
  }

  /**
   * 上传图片到cos桶
   * @param
   * @returns
   */
  putImgToCos(params: { cos_url: string; cos_auth: string; img: File }) {
    const cosReq = new Request('', {
      baseURL: params.cos_url,
      headers: {
        'Content-Type': params.img.type,
        'Content-Length': params.img.size.toString(),
        Authorization: params.cos_auth,
      },
    });
    return cosReq.put('', params.img);
  }

  /**
   * 获取图片临时链接
   * @param
   * @returns
   */

  getTemporaryLink(params: { cos_id: string; job_certify_id: string; upload_resource: string }) {
    return this.requestInstance.post(['personal', 'certify', 'download_url'], params);
  }

  /**
   * 获取手机验证码
   * @param params
   * @returns
   */
  sendSmsCode(params: Record<string, any>) {
    return this.requestInstance.post(
      ['personal', 'certify', 'personal_account_job_certify_info_send_sms_code'],
      params,
    );
  }

  /**
   * 保存个人租户用户认证信息
   * @param params
   * @returns
   */
  saveAuthInfo(params: Record<string, any>) {
    return this.requestInstance.post(
      ['personal', 'certify', 'save_personal_account_job_certify_info'],
      params,
    );
  }

  /**
   * 获取个人租户用户认证信息
   * @returns
   */
  getAuthInfo(authType: AUTH_TYPE) {
    return this.requestInstance.get(
      ['personal', 'certify', 'get_personal_account_job_certify_info'],
      {
        certify_type: _.toNumber(authType),
        app_type: JSRuntime.appFromUrl,
      },
    );
  }

  /**
   * 撤回个人租户用户认证
   * @param jobId 认证id
   * @returns
   */
  revokeAuth(jobId: string) {
    return this.requestInstance.put(
      ['personal', 'certify', 'revoke_personal_account_job_certify'],
      { job_certify_id: jobId },
    );
  }

  /**
   * 获取人脸识别结果
   * @param params
   * @returns
   */
  getFaceCheckResult(params: Record<string, any>) {
    return this.requestInstance.post(
      ['personal', 'certify', 'face_auth', 'get_detect_info'],
      params,
    );
  }

  /**
   * 图片上传
   * @param file
   * @returns
   */
  uploadFile(file: FormData) {
    return this.sendFormData({
      url: ['personal', 'certify', 'personal_account_job_certify_info_upload'],
      data: file,
    });
  }

  getAppMeta(accountId: string, clientId: string) {
    return this.accountInstance.get([accountId, '.well-known', 'oauth-client', clientId]);
  }

  checkAuthorization(accountId: string, params: { redirect_url: string; consent: boolean }) {
    const form = document.createElement('form');
    const formData = new FormData();
    formData.append('redirect_url', params.redirect_url);
    formData.append('consent', params.consent.toString());
    form.innerHTML = `
    <input name="redirect_url" value="${params.redirect_url}"/>
    <input name="consent" value="${params.consent}"/>`;
    form.action = `${JSRuntime.oauth2Site}/v1/account/${accountId}/consent`;
    form.method = 'POST';
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
export default new AccountsApi();
