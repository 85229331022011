/**
 * api 接口层拦截处理 滑块验证事项
 * */

import RequestPromise from 'common/apiRequest/Request/RequestPromise';
import AliCaptcha from 'common/components/AliCaptcha';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { CAPTCHA_ERROR } from 'common/constants/Constants';
const tencentTCaptchaClass = `OneID_TCaptcha`;
const aliCaptchaClass = `OneID_Ali_Captcha`;

function getTencentCaptcha() {
  // 如果有了 TCaptcha 引用，则不重复引用
  const scriptTags = document.getElementsByClassName(tencentTCaptchaClass);
  return scriptTags.length > 0
    ? Promise.resolve()
    : Utils.requireJS(`${JSRuntime.cdnUrl}/static/js/TCaptcha.js`, {
        className: tencentTCaptchaClass,
      }).catch((err: Error) => {
        console.error(err);
      });
}
function getAliCaptcha() {
  // 如果有了 Ali Captcha 引用，则不重复引用
  const aliScriptTags = document.getElementsByClassName(aliCaptchaClass);
  return aliScriptTags.length > 0
    ? Promise.resolve()
    : Utils.requireJS(`${JSRuntime.cdnUrl}/static/js/AliCaptcha.js`, {
        className: aliCaptchaClass,
      }).catch((err: Error) => {
        console.error(err);
      });
}

export type TCaptchaOptions = {
  appId: string;
  cloudType: string;
  appKey: string;
  scene: string;
};

export type TCaptchaVerification =
  | { ticket: string; randStr: string }
  | { sessionId: string; sig: string; token: string };

enum CaptchaEvent {
  show = 'show',
}

type TEventCallBack = () => void;

class CaptchaPack {
  private captchaRef: TencentCaptcha | AliCaptcha | null = null;
  private eventsCallback: Record<CaptchaEvent, Set<TEventCallBack>> = {
    [CaptchaEvent.show]: new Set(),
  };
  public static Event = CaptchaEvent;
  public once = (event: CaptchaEvent, callbackOnce: TEventCallBack) => {
    if (!this.eventsCallback[event]) {
      this.eventsCallback[event] = new Set();
    }
    const func = () => {
      callbackOnce();
      this.eventsCallback[event].delete(func);
    };
    this.eventsCallback[event].add(func);
  };

  // 唯一对外输出方法
  public run = async (
    callback: (captchaVerification?: TCaptchaVerification) => RequestPromise<any>,
  ) => {
    const requestApi = callback();
    const [err, res] = await Utils.resolvePromise(requestApi);
    if (err) return requestApi;

    const successType = _.get(res, 'next.type');
    // 没触发滑块验证，直接返回数据
    if (successType !== 'CAPTCHA_OPTIONS') return requestApi;

    // 触发了滑块验证的场景
    const captchaOptions = _.get(res, 'next.captchaOptions');
    const [captchaErr, captchaVerification] = await Utils.resolvePromise(
      this.showCaptcha(captchaOptions),
    );
    if (captchaErr) {
      return new RequestPromise(Promise.reject(captchaErr), requestApi.abort);
    }

    return callback(captchaVerification);
  };

  private activeCaptcha = async (
    captchaAppId: string,
    onSuccess: (captchaVerification: TCaptchaVerification) => void,
    onError?: () => void,
  ) => {
    try {
      if (this.captchaRef && this.captchaRef instanceof TencentCaptcha) {
        this.captchaRef.destroy();
      }
      /**
       * 生成一个验证码对象
       * - captchaAppId: 登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
       * - callback: 定义的回调函数
       */
      const captcha = new TencentCaptcha(
        captchaAppId,
        (res) => {
          // 继续登录
          if (m.get(res, 'ret') === 0) {
            onSuccess({
              ticket: _.get(res, 'ticket'),
              randStr: _.get(res, 'randstr'),
            });
          } else {
            onError?.();
          }
        },
        {},
      );
      this.captchaRef = captcha;
      // 调用方法，显示验证码
      captcha.show();
    } catch (error) {
      // TCaptcha.js加载异常
      console.error(new Error(error));
    }
  };

  private activeAliCaptcha = (
    appKey: string,
    scene: string,
    onSuccess: (res: TCaptchaVerification) => void,
    onError?: () => void,
  ) => {
    try {
      if (this.captchaRef && this.captchaRef instanceof AliCaptcha) {
        this.captchaRef.hide();
      }
      // 生成一个验证码对象
      const captcha = new AliCaptcha(
        appKey,
        scene,
        (data: { sessionId: string; sig: string; token: string }) => {
          onSuccess(data); // 继续登录
        },
        (errorCode: string) => {
          console.error(new Error(errorCode));
          onError?.();
        },
      );
      this.captchaRef = captcha;
      // 调用方法，显示验证码
      captcha.show();
    } catch (error) {
      // TCaptcha.js加载异常
      console.error(new Error(error));
    }
  };

  private showCaptcha = async (captchaOptions: TCaptchaOptions) => {
    const { cloudType, appId, scene } = captchaOptions;
    const [err] = await Utils.resolvePromise(
      cloudType === 'tencent' ? getTencentCaptcha() : getAliCaptcha(),
    );
    if (err) return Promise.reject(err);

    return new Promise((resolve, reject) => {
      this.eventsCallback?.[CaptchaEvent.show]?.forEach((func) => func());
      if (cloudType === 'tencent') {
        this.activeCaptcha(
          appId,
          (res) => resolve(res),
          () => reject({ data: { errCode: CAPTCHA_ERROR, errMessage: 'captcha 取消或发生错误' } }),
        );
      } else {
        this.activeAliCaptcha(
          appId,
          scene,
          (res) => resolve(res),
          () => reject({ data: { errCode: CAPTCHA_ERROR, errMessage: 'captcha 取消或发生错误' } }),
        );
      }
    });
  };
}

export default CaptchaPack;
